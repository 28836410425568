<template>
  <div class="content">
    <div class="join-title">
      <div class="line"></div>
      <div class="circle"></div>
      <div class="text">申请加入</div>
      <div class="circle"></div>
      <div class="line"></div>
    </div>
    <div class="form-container">
      <el-form :model="form" label-width="120px" :rules="rules" ref="form">
        <el-row>
          <el-col :span="colNumber">
            <el-form-item label="公司/团队名称" prop="name" class="form-item">
              <el-input class="item" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="colNumber">
            <el-form-item label="管理员账号" prop="username" class="form-item">
              <el-input class="item" v-model="form.username"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="colNumber">
            <el-form-item label="管理员密码" prop="password" class="form-item">
              <el-input
                class="item"
                v-model="form.password"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="colNumber">
            <el-form-item label="确认密码" prop="conPassword" class="form-item">
              <el-input
                class="item"
                v-model="form.conPassword"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="colNumber">
            <el-form-item label="联系人姓名" class="form-item">
              <el-input class="item" v-model="form.contactName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="colNumber">
            <el-form-item
              label="联系人电话"
              prop="contactPhone"
              class="form-item"
            >
              <el-input class="item" v-model="form.contactPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-button"
            @click="submitForm('form')"
            >提交</el-button
          >
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "registration",
  data() {
    const isMoblieNumber = (rule, value, callback) => {
      console.log("value===", value);
      setTimeout(() => {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }, 1000);
    };
    const isConPassword = (rule, value, callback) => {
      setTimeout(() => {
        if (this.form.password !== this.form.conPassword) {
          callback(new Error("确认密码和管理员密码不一致!"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      form: {
        name: null, //公司团队名称
        username: null, //管理员账号
        password: null, //密码
        conPassword: null, // 确认密码
        contactName: null, //联系人姓名
        contactPhone: null //联系人方式
      },
      colNumber: 12,
      rules: {
        name: [
          { required: true, message: "请输入公司/团队名称", trigger: "blur" }
        ],
        username: [
          { required: true, message: "请输入管理员账号", trigger: "blur" },
          { min: 5, max: 20, message: "长度在 5-20 个字符", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入管理员密码", trigger: "blur" },
          { min: 6, message: "密码长度不低于6位", trigger: "blur" }
        ],
        conPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: isConPassword, trigger: "blur" }
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { validator: isMoblieNumber, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    ...mapActions("user", ["register"]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.register({ ...this.form }).then(res => {
            if (res.data.code === 2000) {
              this.$message.success("注册成功！");
              this.$router.push({
                name: "login"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.item {
  width: 300px;
  border-color: teal;
}

.form-item /deep/ .el-input__inner:focus {
  //less 语法的样式穿透
  border: teal solid 1px;
}
.content {
  margin: 0 auto;
  margin-top: 180px;
  position: relative;
}
.form-container {
  border: teal solid 1px;
  padding: 20px;
}
.join-title {
  height: 80px;
  text-align: center;
}
.line {
  border: solid teal 1px;
  width: 150px;
  display: inline-block;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: teal;
  border-radius: 5px;
  display: inline-block;
}
.text {
  display: inline-block;
  font-size: 20px;
}
.submit-button {
  background-color: teal;
  border: teal;
}
</style>
